import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel
} from '@mui/material';
import proxy from '../../api/axios';
import { toast } from 'react-toastify';

const CreateUser = ({ user, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user'); // Default role is 'User'
  const [isPremiumUser, setIsPremiumUser] = useState(false);

  // Pre-fill form fields if user is passed for editing
  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setRole(user.role);
      setIsPremiumUser(user.isPremiumUser);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      name,
      email,
      password,
      role,
      isPremiumUser,
    };

    try {
      if (user) {
        // If editing an existing user, send PUT request
        const { data } = await proxy.put(`/users/${user._id}`, userData);
        toast.success(data.message);
      } else {
        // If creating a new user, send POST request
        const { data } = await proxy.post('/users', userData);
        toast.success(data.message);
      }
      onClose(); // Close the form dialog
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: '20px',
        maxWidth: '600px', // Adjusted width for user creation
        margin: 'auto',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9'
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        {user ? 'Edit User' : 'Create User'}
      </Typography>

      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />

      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />

      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          labelId="role-label"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          label="Role"
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="softadmin">Soft Admin</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            checked={isPremiumUser}
            onChange={(e) => setIsPremiumUser(e.target.checked)}
          />
        }
        label="Premium User"
        sx={{ mb: 2 }}
      />

      <Button type="submit" variant="contained" color="primary" fullWidth>
        {user ? 'Update User' : 'Create User'}
      </Button>
    </Box>
  );
};

export default CreateUser;
