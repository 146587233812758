import axios from 'axios';
import { getToken } from '../common/utils';
import { toast } from 'react-toastify';

const proxy = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api'
});

// Example: Set Authorization header if token exists
proxy.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
    }, (error) => {
    return Promise.reject(error);
});

// Response Interceptor: Check for invalid token (e.g., 401 Unauthorized or 403 Forbidden)
proxy.interceptors.response.use(
    (response) => response, // For valid responses, simply return the response
    (error) => {
        if (error.response) {
            const { status } = error.response;

            // Token is invalid, expired, or user is unauthorized
            if (status === 401) {
                toast.error('Session expired. Please log in again.');
                localStorage.removeItem('token');
                setTimeout(() => {
                    window.location.href = '/'; // Redirect to login page
                }, 1500);
            } else if (status === 403) {
                toast.error('Access denied. You do not have permission.');
                localStorage.removeItem('token');
                setTimeout(() => {
                    window.location.href = '/'; // Redirect to login page
                }, 1500);
            }
        }
        
        return Promise.reject(error); // Pass error down to the calling service
    }
);

export default proxy;
