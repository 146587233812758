import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateContest from './CreateContest'; // Import the CreateContest component
import proxy from '../../api/axios';

const ManageContests = () => {
  const [contests, setContests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedContest, setSelectedContest] = useState(null);

  useEffect(() => {
    // Fetch all contests on component mount
    fetchContests();
  }, []);

  const fetchContests = async () => {
    try {
      const {data} = await proxy.get('/contests/admin');
      setContests(data);
    } catch (error) {
      console.error('Error fetching contests:', error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this contest?')) {
      try {
        await proxy.delete(`/contests/${id}`);
        fetchContests(); // Refresh the contests after deletion
      } catch (error) {
        console.error('Error deleting contest:', error.message);
      }
    }
  };

  const handleEdit = (contest) => {
    setSelectedContest(contest);
    setOpenDialog(true);
  };

  const handleCreateNewContest = () => {
    setSelectedContest(null); // Clear selected contest for new creation
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    fetchContests(); // Refresh the contest list after creation/editing
  };

  return (
    <Box sx={{ padding: '20px', width: '80%', margin: '0 auto' }}>
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        Manage Contests
      </Typography>

      {/* Create Contest Button */}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        color="primary"
        sx={{ mb: 3 }}
        onClick={handleCreateNewContest}
      >
        Create Contest
      </Button>

      {/* Contests Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Title</strong></TableCell>
              <TableCell><strong>Start Time</strong></TableCell>
              <TableCell><strong>End Time</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contests.map((contest) => (
              <TableRow key={contest._id}>
                <TableCell>{contest.title}</TableCell>
                <TableCell>{new Date(contest.startTime).toLocaleString()}</TableCell>
                <TableCell>{new Date(contest.endTime).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(contest)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(contest._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Editing or Creating a Contest */}
      <Dialog open={openDialog} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogTitle>{selectedContest ? 'Edit Contest' : 'Create Contest'}</DialogTitle>
        <DialogContent>
          <CreateContest contest={selectedContest} onClose={closeDialog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageContests;
