import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, List, ListItem, Chip, CircularProgress } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import TimerIcon from '@mui/icons-material/Timer';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { showAuth } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
const ContestPaper = styled(Paper)(({ theme }) => ({
  padding: '20px',
  marginBottom: '15px',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    backgroundColor: theme.palette.background.default,
  },
  maxWidth: '1200px',
  margin: 'auto',
}));

const formatDateTime = (dateTime) => {
  const dateObj = new Date(dateTime);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return { date, time };
};

const formatRemainingTime = (remainingTime) => {
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

const ContestsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingTimes, setRemainingTimes] = useState([]);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInInfo = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const { data } = await proxy.get('/contests');
        setContests(data);
      } catch (error) {
        console.error('Error fetching contests:', error);
        toast.error('Failed to load contests');
      } finally {
        setLoading(false);
      }
    };
    fetchContests();
  }, []);

  // Timer setup
  useEffect(() => {
    const updateRemainingTimes = () => {
      const currentTime = new Date();
      const updatedRemainingTimes = contests.map(contest => {
        const contestStartTime = new Date(contest.startTime);
        return {
          contestId: contest.id,  // Store contest ID
          remainingTime: contestStartTime - currentTime,
        };
      });
      setRemainingTimes(updatedRemainingTimes);
    };

    // Initial update
    updateRemainingTimes();
    
    const intervalId = setInterval(updateRemainingTimes, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [contests]);

  const handleViewResults = (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    navigate(`${contestId}/result`);
  };

  const handleStartNow = async (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    try {
      await proxy.post(`/contests/join`, { id: contestId });
      navigate(`/live-contest/${contestId}`);
    } catch (error) {
      console.error('Error fetching contest details:', error);
      toast.error('Could not start the contest');
    }
  };

  const isContestActive = (startTime, endTime) => {
    const currentTime = new Date();
    const contestStartTime = new Date(startTime);
    const contestEndTime = new Date(endTime);
    return contestStartTime <= currentTime && contestEndTime >= currentTime;
  };

  const isContestCompleted = (endTime) => {
    const currentTime = new Date();
    const contestEndTime = new Date(endTime);
    return contestEndTime < currentTime;
  };

  const getContestStatus = (contest) => {
    const currentTime = new Date();
    if (currentTime < new Date(contest.startTime)) {
      return 'Upcoming';
    }
    if (isContestActive(contest.startTime, contest.endTime) && contest.status !== 'Already Submitted' && contest.status !== 'Time Expired') {
      return 'In Progress';
    }
    if (isContestCompleted(contest.endTime) || contest.status === 'Already Submitted' || contest.status === 'Time Expired') {
      return 'Completed'; 
    }
    return 'Unknown';
  };

  const getChipColor = (status) => {
    switch (status) {
      case 'Upcoming':
        return 'info';
      case 'In Progress':
        return 'primary';
      case 'Completed':
        return 'success';
      default:
        return 'default';
    }
  };

  // Separate contests into active and completed arrays
  const sortedContests = {
    active: contests.filter(contest => !isContestCompleted(contest.endTime)).sort((a, b) => new Date(a.startTime) - new Date(b.startTime)),
    completed: contests.filter(contest => isContestCompleted(contest.endTime)),
  };

  return (
    <Box sx={{ padding: '20px', width: '85%', margin: 'auto' }}>
      <Helmet>
        <title>Weekly Coding Contests | CrafterHack</title>
        <meta name="description" content={`This is the page for viewing and participating in weekely coding contests`} />
      </Helmet>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Contests
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
          <span style={{ marginLeft: 7, color: '#1497c1' }}>Loading Contests</span>
        </Box>
      ) : (
        <List>
          {/* Render active contests */}
          {sortedContests.active.map((contest) => {
            const status = getContestStatus(contest);
            const remainingInfo = remainingTimes.find(time => time.contestId === contest.id);
            const remainingTime = remainingInfo ? remainingInfo.remainingTime : 0; // Get the remaining time for the contest
            const { days, hours, minutes, seconds } = formatRemainingTime(remainingTime);
            return (
              <ContestPaper key={contest.id} style={{ marginTop: 15 }}>
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px',
                  }}
                >
                  <Box sx={{ flexGrow: 1, maxWidth: '700px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem', letterSpacing: 1 }}>
                      {contest.title}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontSize: '0.8rem', letterSpacing: 1 }}>
                      {contest.description}
                    </Typography>

                    {/* Status Chip */}
                    <Chip
                      label={status}
                      color={getChipColor(status)}
                      sx={{ mt: 1, fontWeight: 'bold' }}
                    />
                  </Box>

                  <Box sx={{ textAlign: 'right', ml: 4 }}>
                    {status === 'Upcoming' && (
                      <Chip
                        label={`Starts in: ${days > 0 ? `${days} days ` : ''} ${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`}
                        color="warning"
                        sx={{
                          mb: 1,
                          fontWeight: 'bold',
                          background: 'linear-gradient(135deg, rgb(23 176 234) 0%, rgb(188 27 27) 100%)',
                          color: '#FFF',
                          '&:hover': {
                            background: 'linear-gradient(135deg, rgba(34, 139, 34, 1) 0%, rgba(144, 238, 144, 1) 100%)',
                          },
                        }}
                      />
                    )}

                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <EventIcon fontSize="small" /> Start: {formatDateTime(contest.startTime).date} at {formatDateTime(contest.startTime).time}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <EventIcon fontSize="small" /> Ends: {formatDateTime(contest.endTime).date} at {formatDateTime(contest.endTime).time}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <TimerIcon fontSize="small" /> Duration: {contest.duration} mins
                    </Typography>

                    {(status === 'In Progress' || loggedInInfo?.role === 'admin') && (
                      <Button onClick={() => handleStartNow(contest.id)} variant="contained" color="success">
                        <PlayCircleFilledWhiteIcon fontSize="small" sx={{ marginRight: '5px' }} />
                        { contest.timeRemaining ? 'Resume' : 'Start Now' }
                      </Button>
                    )} 
                    {(status === 'Completed' || loggedInInfo?.role === 'admin') &&  (
                      <Button sx={{ ml:2   }} onClick={() => handleViewResults(contest.id)} variant="outlined" color="secondary">
                        View Results
                      </Button>
                    )}
                  </Box>
                </ListItem>
              </ContestPaper>
            );
          })}

          {/* Render completed contests */}
          {sortedContests.completed.length > 0 && (
            <>
              {sortedContests.completed.map((contest) => (
                <ContestPaper key={contest.id} style={{ marginTop: 15 }}>
                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '5px',
                    }}
                  >
                    <Box sx={{ flexGrow: 1, maxWidth: '700px' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem', letterSpacing: 1 }}>
                        {contest.title}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 1, fontSize: '0.8rem', letterSpacing: 1 }}>
                        {contest.description}
                      </Typography>

                      {/* Status Chip */}
                      <Chip
                        label="Completed"
                        color="success"
                        sx={{ mt: 1, fontWeight: 'bold' }}
                      />
                    </Box>

                    <Box sx={{ textAlign: 'right', ml: 4 }}>
                      <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                        <EventIcon fontSize="small" /> Start: {formatDateTime(contest.startTime).date} at {formatDateTime(contest.startTime).time}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                        <EventIcon fontSize="small" /> Ends: {formatDateTime(contest.endTime).date} at {formatDateTime(contest.endTime).time}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                        <TimerIcon fontSize="small" /> Duration: {contest.duration} mins
                      </Typography>

                      <Box>
                        <Button onClick={() => handleViewResults(contest.id)} variant="outlined" color="secondary">
                          View Results
                        </Button>
                      </Box>
                    </Box>
                  </ListItem>
                </ContestPaper>
              ))}
            </>
          )}
        </List>
      )}
    </Box>
  );
};

export default ContestsPage;
