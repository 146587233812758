// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    showAuth(state, action) {
      state.isOpen = true;
    },
    hideAuth(state, action) {
      state.isOpen = false;
    }
  },
});

export const { showAuth, hideAuth } = authSlice.actions;
export default authSlice.reducer;
