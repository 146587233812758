import React, { useEffect, useState } from 'react';
import { 
  Box, Typography, Paper, Avatar, List, ListItem, Button, 
  CircularProgress, Alert, useTheme, TextField, Dialog, 
  DialogActions, DialogContent, DialogTitle, IconButton, Stack, Divider, Chip 
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Edit, Save, Close, Code as CodeIcon, Lock as LockIcon, Star as StarIcon } from '@mui/icons-material';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import { getToken } from '../common/utils';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

// Register Chart.js components
ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const coderImage = 'https://cdn.pixabay.com/photo/2017/08/05/00/12/code-2580999_960_720.jpg'; // Coder-themed default avatar

const UserProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { username } = useParams();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [name, setName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const endpoint = username ? `/users/${username}` : '/users';
      const { data } = await proxy.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });

      if (!data.user) {
        toast.error('No user exists with this username');
        navigate('/');
        return;
      }
      setUser(data.user);
      setName(data.user.name); // Initialize name field with user name
    } catch (err) {
      setError('Failed to load user data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [username]);

  const handleEditProfile = async () => {
    if(!name || (showPasswordFields && (!newPassword || !currentPassword))){
      toast.error('Enter required fields');
      return;
    } else if (showPasswordFields && newPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    } else if(showPasswordFields && newPassword?.length<8) {
      toast.info('Please enter a strong password minimum of 8 characters');
      return;
    }
    try {
      const token = getToken();
      const payload = { 
        name, 
        ...(showPasswordFields && newPassword ? { password: newPassword } : {}),
        currentPassword,
        newPassword
      };

      await proxy.put(`/users/profile/${user._id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Profile updated successfully');
      setEditModalOpen(false);
      fetchUserDetails();
    } catch (err) {
      toast.error('Failed to update profile. Please try again.');
    }
  };

  const chartData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Problems Solved Over Time',
        data: [3, 5, 2, 7],
        backgroundColor: '#4caf50',
        borderColor: '#4caf50',
        fill: false,
      },
    ],
  };

  const chartOptions = { responsive: true, maintainAspectRatio: false };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2, color: '#1497c1' }}>Loading User Profile...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, bgcolor: 'background.default', borderRadius: 3, maxWidth: 900, margin: 'auto' }}>
      <Helmet>
        <title>{user.name} - {user.username} | Profile | CrafterHack</title>
        <meta name="description" content={`View ${user.name}'s profile and track solved problems over time.`} />
      </Helmet>

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Stack direction="row" alignItems="center">
        <Avatar
            alt={user.name}
            src={user.image || coderImage}
            sx={{
              width: 120,
              height: 120,
              mr: 3,
              bgcolor: '#1e88e5',
              color: 'white',
              fontSize: 40,
            }}
          />
          <Box>
            <Typography variant="h5" fontWeight="bold">{user.name}</Typography>
            <Typography variant="body2" color="gray">@{user.username}</Typography>
            <Chip
              icon={<StarIcon />}
              label={user.isPremiumUser ? 'Premium' : 'Standard'}
              color={user.isPremiumUser ? 'primary' : 'default'}
              sx={{ mt: 1 }}
            />
          </Box>
        </Stack>
        { !username && 
        <IconButton onClick={() => setEditModalOpen(true)} color="primary">
          <Edit />
        </IconButton>
        }
      </Stack>

      <Paper sx={{ p: 2, mb: 2, boxShadow: 4, borderRadius: 2 }}>
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center"
      >
        <Typography variant="body" fontWeight={500}>
          Points: <Box component="span" fontWeight={700}>{user.points || 0}</Box>
        </Typography>

        <Typography variant="body" fontWeight={500}>
          Problems Solved: <Box component="span" fontWeight={700}>{user.solvedProblems?.length || 0}</Box>
        </Typography>
      </Box>
    </Paper>

      <Paper sx={{ p: 3, mb: 3, boxShadow: 4 }}>
        <Typography variant="body" mb={2} fontWeight="bold">Solved Problems Over Time</Typography>
        <Box sx={{ position: 'relative', height: 300 }}>
          <Line data={chartData} options={chartOptions} />
        </Box>
      </Paper>

      <Typography variant="body" fontWeight="bold" sx={{ mb: 1 }}>Problems Solved</Typography>
      <List>
        {user.solvedProblems.map((problem) => (
          <ListItem key={problem.slug} sx={{ mb: 1 }}>
            <Button
              component={Link}
              to={`/problems/${problem.slug}`}
              variant="contained"
              fullWidth
              sx={{
                bgcolor: theme.palette.mode === 'dark' ? '#333' : '#fff',
                color: theme.palette.mode === 'dark' ? '#fff' : '#333',
                textAlign: 'left',
                '&:hover': { bgcolor: '#115293', color: 'white' },
                borderRadius: 2,
              }}
            >
              <CodeIcon sx={{ mr: 1 }} />
              {problem.title}
            </Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2, mt:1 }}
          />
          <Button 
            startIcon={<LockIcon />} 
            onClick={() => setShowPasswordFields(!showPasswordFields)}
            sx={{ mb: 2 }}
          >
            {showPasswordFields ? 'Cancel Password Change' : 'Change Password'}
          </Button>
          {showPasswordFields && (
            <>
              <TextField
                label="Current Password"
                fullWidth
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              
              <TextField
                label="New Password"
                fullWidth
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Confirm New Password"
                fullWidth
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} startIcon={<Close />}>Cancel</Button>
          <Button onClick={handleEditProfile} startIcon={<Save />} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfilePage;
