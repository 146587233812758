import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateUser from './CreateUser'; // Import the CreateUser component
import proxy from '../../api/axios';
import { toast } from 'react-toastify';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [userToDelete, setUserToDelete] = useState(null); // State to hold user ID to delete

  useEffect(() => {
    // Fetch all users on component mount
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await proxy.get('/users/all');
      setUsers(data.users);
      setFilteredUsers(data.users); // Set filtered users initially to all users
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = users.filter(user => 
      user._id.toLowerCase().includes(e.target.value.toLowerCase())
      || user.name.toLowerCase().includes(e.target.value.toLowerCase())
      || user.email.toLowerCase().includes(e.target.value.toLowerCase())
      || user.role.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsers(filtered); // Update filtered users based on search term
  };

  const handleDeleteConfirmation = (user) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    if (userToDelete) {
      try {
        const { data } = await proxy.delete(`/users/${userToDelete._id}`);
        toast.success(data.message);
        fetchUsers(); // Refresh the users list after deletion
      } catch (error) {
        console.error('Error deleting user:', error.message);
      }
    }
    setOpenDeleteDialog(false); // Close the delete dialog
    setUserToDelete(null); // Clear the user to delete
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCreateNewUser = () => {
    setSelectedUser(null); // Clear selected user for new creation
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    fetchUsers(); // Refresh the user list after creation/editing
  };

  return (
    <Box sx={{ padding: '20px', width: '80%', margin: '0 auto' }}>
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        Manage Users - ({users.length})
      </Typography>

      {/* Search Bar */}
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {/* Create User Button */}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        color="primary"
        sx={{ mb: 3 }}
        onClick={handleCreateNewUser}
      >
        Create User
      </Button>

      {/* Users Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Id</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Role</strong></TableCell>
              <TableCell><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user._id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteConfirmation(user)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Editing or Creating a User */}
      <Dialog open={openDialog} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogTitle>{selectedUser ? 'Edit User' : 'Create User'}</DialogTitle>
        <DialogContent>
          <CreateUser user={selectedUser} onClose={closeDialog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete {userToDelete?.name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageUsers;
