import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Drawer, List, ListItem, Box, TextField, Badge } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Link, NavLink } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import AuthModal from './auth/AuthModal';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userSlice';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { toggleTheme } from '../redux/themeSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch(logout());
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [isLoggedIn]);

  const menuItems = [
    {
      text: 'Mock Interviews',
      path: '/schedule-mock-interviews',
      badge: true, // Mark this as the item with the badge
    },
    { text: 'Programs', path: '/programs'},
    { text: 'Problems', path: '/problems' },
    { text: 'Contests', path: '/contests' },
    // { text: 'Leaderboard', path: '/leaderboard' },
    { text: 'Compiler', path: '/ide', },
  ];  

  if (loggedInUser?.isPremiumUser) {
    menuItems.push({ text: 'DSA Programs', path: '/dsa-programs' });
  }

  if (!isLoggedIn) {
    menuItems.push({ text: 'SignIn', path: '#', onClick: () => setAuthModalOpen(true) });
  }
  const handleThemeChange = () => {
    dispatch(toggleTheme());
  }; 

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          minHeight:'55px'
        }}
      >
        <Toolbar>
          <Typography
            component={Link} to={'/'}
            variant="h4"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              flexGrow: 1,
              fontWeight: 'bold',
              color: theme.palette.mode === 'dark' ? 'rgb(6 243 234)' : '#3D5AFE',
              textShadow: theme.palette.mode === 'dark'
                ? '2px 2px 4px rgba(0, 0, 0, 0.7)'
                : '2px 2px 4px rgba(150, 150, 150, 0.5)',
              letterSpacing: '0.1em',
              fontFamily: '"Courier New", Courier, monospace'
            }}
          >
            Crafter<span style={{ color: '#FF4081', marginLeft: 5 }}>Hack</span>
          </Typography>

          {/* Search Box */}
          {searchOpen && (
            <TextField
              variant="outlined"
              placeholder="Search..."
              sx={{
                marginLeft: 2,
                transition: 'width 0.3s',
                width: { xs: '100%', sm: '300px' },
                bgcolor: theme.palette.background.paper,
              }}
              size="small"
            />
          )}
          {/* <IconButton color="inherit" onClick={() => setSearchOpen(!searchOpen)}>
            <SearchIcon />
          </IconButton> */}
          {!isMobile ? (
            <Box sx={{ position: 'relative' }}>
              {menuItems.map((item, index) => (
                <Box key={index} sx={{ position: 'relative', display: 'inline-block', mr: 1 }}>
                  <Button
                    color="inherit"
                    component={NavLink}
                    to={item.path}
                    onClick={item.onClick}
                    sx={{   
                      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      position: 'relative',
                      fontWeight: item.badge || item.text==='SignIn' ? 'bold' : 'normal',
                      background: item.badge || item.text==='SignIn' ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' : 'inherit',
                      color: item.badge || item.text==='SignIn' ? '#fff' : 'inherit',
                      borderRadius: item.badge || item.text==='SignIn' ? '8px' : 'inherit',
                      padding: item.badge || item.text==='SignIn' ? '4px 9px' : 'inherit',
                      '&:hover': {
                        background: item.badge || item.text==='SignIn' ? '#FF4081' : 'inherit',
                      },
                      fontSize: '12px'
                    }}
                  >
                    {item.text === 'SignIn' && <LockOpenIcon sx={{ mr: 0.5 }} />}
                    {item.text}
                  </Button>

                  {/* Badge for Mock Interviews */}
                  {item.badge && (
                    <Badge
                      badgeContent="New"
                      color="error"
                      overlap="rectangular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      sx={{
                        position: 'absolute',
                        top: -3,  
                        right: -5,
                        backgroundColor: '#FF4081',
                        color: '#fff',
                        borderRadius: '50%',
                        fontSize: '0.4rem',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
                        animation: 'pulse 1.5s infinite ease-in-out',
                      }}
                    />
                  )}
                </Box>
              ))}
              {isLoggedIn && (
                <>
                  <Button color="inherit" onClick={handleMenu} style={{ fontSize: '12px' }}>
                    <AccountCircle sx={{ width: 30, height: 30, mr: 1 }} />
                    {loggedInUser.name.split(' ').length > 2
                      ? loggedInUser.name.split(' ').slice(0, 2).join(' ')
                      : loggedInUser.name.split(' ')[0]}
                  </Button>

                  <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {loggedInUser.role === 'admin' && ( // Only show these items if the user is an admin
                      <>
                        <MenuItem style={{ fontSize: '13px' }} component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                        <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-contests' onClick={handleClose}>Manage Contests</MenuItem>
                        <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-users' onClick={handleClose}>Manage Users</MenuItem>
                      </>
                    )}
                    {loggedInUser.role === 'softadmin' && ( // Only show these items if the user is an admin
                      <>
                        <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                      </>
                    )}
                    <MenuItem style={{ fontSize: '13px' }}  component={Link} to={`profile`} onClick={handleClose}>My Profile</MenuItem>
                    <MenuItem style={{ fontSize: '13px' }}  onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          ) : (
            <Button style={{ fontSize: '12px' }}  color="inherit" onClick={toggleDrawer(true)}>
              <FormatListBulletedIcon sx={{ ml: 2 }} />
            </Button>
          )}
          <IconButton onClick={handleThemeChange} color="inherit">
            {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem style={{ fontSize: '13px' }} button component={NavLink} to={item.path} key={index} onClick={item.onClick}>
              {item.text}
            </ListItem>
          ))}
          {isLoggedIn && (
            <>
              <Button style={{ fontSize: '12px' }}  color="inherit" onClick={handleMenu}>
                <AccountCircle sx={{ width: 30, height: 30, mr: 1 }} />
                {loggedInUser.name}
              </Button>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {loggedInUser.role === 'admin' && ( // Only show these items if the user is an admin
                  <>
                    <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                    <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-contests' onClick={handleClose}>Manage Contests</MenuItem>
                    <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-users' onClick={handleClose}>Manage Users</MenuItem>
                  </>
                )}
                {loggedInUser.role === 'softadmin' && ( // Only show these items if the user is an admin
                  <>
                    <MenuItem style={{ fontSize: '13px' }}  component={Link} to='admin/manage-problems' onClick={handleClose}>Manage Problems</MenuItem>
                  </>
                )}
                <MenuItem style={{ fontSize: '13px' }}  component={Link} to={`profile`} onClick={handleClose}>My Profile</MenuItem>
                <MenuItem style={{ fontSize: '13px' }}  onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </List>
      </Drawer>

      {/* Auth Modal */}
      <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
    </>
  );
};

export default Navbar;
