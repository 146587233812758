import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  CircularProgress,
  InputBase,
  IconButton,
  Divider,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { deepPurple, blue, green } from '@mui/material/colors';
export const trophyColors = [green[400], blue[400], deepPurple[400]];

// Styled components for UI elements
export const TrophyIcon = styled(EmojiEventsIcon)(({ theme, color }) => ({
  color: color || theme.palette.secondary.main,
  fontSize: '30px',
}));

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  marginBottom: theme.spacing(2),
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#333',
    boxShadow: theme.shadows[4],
  },
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
}));

const LeaderboardPage = () => {
  const loggedInUser = useSelector((state)=>state.user.userInfo);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userRank, setUserRank] = useState(null); // State to store current user rank
  const usersPerPage = 10;
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const fetchLeaderboard = async () => {
    try {
      const response = await proxy.get('/users/leaderboard');
      setUsers(response.data.leaderboard);
      console.log(loggedInUser);
      
      const rank = response.data.leaderboard.findIndex((user) => user.id === loggedInUser?._id) + 1;
      setUserRank(rank); // Set the current user rank
    } catch (error) {
      console.error('Error fetching leaderboard data: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleUserClick = (username) => {
    navigate(`/user/${username}`);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startIndex = (page - 1) * usersPerPage;
  const selectedUsers = filteredUsers.slice(startIndex, startIndex + usersPerPage);

  return (
    <Box sx={{ padding: '20px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
      <Helmet>
        <title>Leaderboard | Track your coding progress | CrafterHack</title>
        <meta name="description" content="This is the page for tracking your progress on solved coding questions" />
      </Helmet>
      <Box sx={{ width: { xs: '100%', sm: '80%' }, mx: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Leaderboard</Typography>

          {/* Current User Rank - Displayed in Upper Left */}
          {userRank && (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', color: 'text.secondary' }}
            >
              My Rank: {userRank}
            </Typography>
          )}
        </Box>

        {/* Search Bar */}
        <SearchContainer>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search Username…"
            inputProps={{ 'aria-label': 'search' }}
            value={searchTerm}
            onChange={(e) => { setSearchTerm(e.target.value); setPage(1); }}
          />
        </SearchContainer>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper sx={{ width: '100%', padding: '20px', bgcolor: 'background.default' }}>
            <List>
              {selectedUsers.map((user, index) => (
                <ListItem
                  key={index}
                  sx={{
                    mb: 2,
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.005)',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleUserClick(user.username)}>
                    <ListItemAvatar>
                      {!searchTerm && startIndex + index < 3 ? (
                        <TrophyIcon color={trophyColors[startIndex + index]} />
                      ) : (
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>{users.indexOf(user) + 1}</Avatar>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Typography variant="body" sx={{ cursor: 'pointer', fontWeight: users.indexOf(user) < 3 ? 'bold' : 'normal' }}>
                          {user.name}
                        </Typography>
                      }
                      secondary={<Typography sx={{ color: 'text.secondary' }}>Points: {user.points}</Typography>}
                    />
                  </Box>
                  {users.indexOf(user) <= 4 ? (
                    <StarIcon sx={{ color: 'warning.main' }} />
                  ) : (
                    <StarIcon sx={{ color: 'warning' }} />
                  )}
                </ListItem>
              ))}
            </List>

            {/* Pagination Controls */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={Math.ceil(filteredUsers.length / usersPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default LeaderboardPage;
