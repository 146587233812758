import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Tabs, Tab, Divider, Alert, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/userSlice';
import { toast } from 'react-toastify';
import { hideAuth } from '../../redux/authSlice';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// Import the ForgotPasswordModal (Assuming you have this component already)
import ForgotPasswordModal from './ForgotPasswordModal';
import proxy from '../../api/axios';
import { GOOGLE_SIGN_IN_CLIENT_ID } from '../../common/constants';

const modalStyle = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2.5,
  borderRadius: '12px',
};

const inputStyle = {
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 20px',
  marginTop: '12px',
};

const googleButtonStyle = {
  background: '#4285F4',
  borderRadius: '8px',
  color: 'white',
  padding: '10px 20px',
  marginTop: '10px',
  textTransform: 'none',
  width: '100%',
};

const dividerStyle = {
  my: 2,
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    display: 'block',
    width: '48%',
    height: '1px',
    backgroundColor: '#e0e0e0',
  },
  '&::before': { float: 'left' },
  '&::after': { float: 'right' },
};

const AuthModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const openingRequest = useSelector((state) => state.auth.isOpen);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({ email: '', password: '', name: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError('');
    setSuccess('');
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    console.log('Google Login Success:', credentialResponse);
    try {
      const { data } = await proxy.post('/auth/google-signin', {
        idToken: credentialResponse.credential,
      });
      processSucessSignIn(data);
    } catch (error) {
      setError('Google SignIn Failed');
    }
  };

  const handleGoogleFailure = (error) => {
    setError('Google SignIn Failed');
    console.log('Google Login Failed:', error);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const processSucessSignIn = (data) => {
    if (data.success) {
      setSuccess('SignIn successful!');
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      dispatch(login(data.user));
      dispatch(hideAuth());
      onClose();
      toast.success('Welcome Back, ' + data.user.name);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      setError(data.message);
    }
  }

  const handleLogin = async () => {
    if(!formData.email || !formData.password){
      toast.info('Please enter email and password'); return;
    }
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const { data } = await proxy.post('/auth/login', {
        email: formData.email,
        password: formData.password,
      });
      processSucessSignIn(data);
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    if(!formData.name || !formData.email || !formData.password){
      toast.info('Enter all the fields'); return;
    } else if(formData.password?.length<8) {
      toast.info('Please enter a strong password minimum of 8 characters'); return;
    }
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const { data } = await proxy.post('/auth/signup', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      });
      processSucessSignIn(data);
    } catch (error) {
      setError(error?.response?.data?.message || 'Sign up failed');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordOpen(true);
    onClose(); // Close auth modal when forgot password is opened
  };

  const handleModalClose = () => {
    setFormData({ email: '', password: '', name: '' });
    dispatch(hideAuth());
    onClose();
  }

  useEffect(() => {
    setFormData({ email: '', password: '', name: '' });
    setError('');
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <GoogleOAuthProvider clientId={GOOGLE_SIGN_IN_CLIENT_ID}>
  <Modal open={open || openingRequest} onClose={onClose}>
    <Box
      sx={{
        ...modalStyle,
        width: { xs: '90%', sm: '80%', md: '60%' }, // Adjust modal width for mobile
        maxHeight: '90vh',
        overflowY: 'auto',
        p: { xs: 2, sm: 4 }, // Padding adjustments for mobile
      }}
    >
      {/* Close Icon */}
      <IconButton
        aria-label="close"
        onClick={handleModalClose}
        sx={{ position: 'absolute', right: 16, top: 16, color: 'grey.500' }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="h6"
        component="h2"
        sx={{ textAlign: 'center', fontSize: { xs: '1.2rem', md: '1.5rem' } }}
      >
        {tabValue === 0 ? 'Welcome Back!' : 'Create Your Account'}
      </Typography>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        textColor="primary"
        indicatorColor="primary"
        sx={{ mb: 1 }}
      >
        <Tab label="Login" sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }} />
        <Tab label="Sign Up" sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }} />
      </Tabs>

      {error && <Alert severity="error" sx={{ mb: 2, fontSize: { xs: '0.8rem', md: '1rem' } }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2, fontSize: { xs: '0.8rem', md: '1rem' } }}>{success}</Alert>}

      {tabValue === 0 ? (
        <>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ ...inputStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            name="password"
            value={formData.password}
            onChange={handleChange}
            sx={{ ...inputStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ ...buttonStyle, fontSize: { xs: '0.8rem', md: '1rem' }, py: { xs: 1, md: 2 } }}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>

          {/* Forgot Password Link */}
          <Typography variant="body2" align="center" sx={{ mt: 2, fontSize: { xs: '0.75rem', md: '0.9rem' } }}>
            <Link href="#" onClick={handleForgotPassword}>
              Forgot Password?
            </Link>
          </Typography>
        </>
      ) : (
        <>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ ...inputStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}
            required
          />
          <TextField
            label="Email"
            fullWidth
            type="email"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ ...inputStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            margin="normal"
            name="password"
            value={formData.password}
            onChange={handleChange}
            sx={{ ...inputStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ ...buttonStyle, fontSize: { xs: '0.8rem', md: '1rem' }, py: { xs: 1, md: 2 } }}
            onClick={handleSignUp}
            disabled={loading}
          >
            {loading ? 'Signing up...' : 'Sign Up'}
          </Button>
        </>
      )}

      <Divider sx={{ ...dividerStyle, fontSize: { xs: '0.8rem', md: '1rem' } }}>OR</Divider>

      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleFailure}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            sx={{ ...googleButtonStyle, fontSize: { xs: '0.8rem', md: '1rem' }, py: { xs: 1, md: 2 } }}
          >
            Continue with Google
          </Button>
        )}
      />
    </Box>
  </Modal>

  {/* Forgot Password Modal */}
  <ForgotPasswordModal open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)} />
</GoogleOAuthProvider>

  );
};

export default AuthModal;
