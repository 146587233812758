import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Chip,
  Stack,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import proxy from '../../api/axios';
import { toast } from 'react-toastify';

const CreateContest = ({ contest, onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [duration, setDuration] = useState(90); // Default duration is 90 minutes
  const [availableProblems, setAvailableProblems] = useState([]); // Assume you fetch available problems
  const [selectedProblems, setSelectedProblems] = useState([]);

  // Pre-fill form fields if contest is passed for editing
  useEffect(() => {
    if (contest) {
      setTitle(contest.title);
      setDescription(contest.description);
      const localStartTime = new Date(contest.startTime).toLocaleString('sv-SE', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour12: false
      }).substring(0, 16);
  
      const localEndTime = new Date(contest.endTime).toLocaleString('sv-SE', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour12: false
      }).substring(0, 16);
  
      setStartTime(localStartTime); // Set to local time for input
      setEndTime(localEndTime);     // Set to local time for input
      setDuration(contest.duration);
      setSelectedProblems(contest.problems);
    }

    // Fetch available problems from the server (assuming an endpoint exists)
    const fetchAvailableProblems = async () => {
      try {
        const { data } = await proxy.get('/problems/admin'); // Adjust endpoint as necessary
        setAvailableProblems(data);
      } catch (error) {
        console.error("Error fetching problems:", error);
      }
    };

    fetchAvailableProblems();
  }, [contest]);

  const handleProblemSelect = (event, newValue) => {
    // Set selectedProblems to unique values
    setSelectedProblems(newValue.map(problem => problem._id));
  };

  const handleProblemRemove = (problemId) => {
    setSelectedProblems(selectedProblems.filter(id => id !== problemId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contestData = {
      title,
      description,
      startTime,
      endTime,
      duration,
      problems: selectedProblems,
      createdBy: 'adminId' // Replace with actual admin ID
    };

    try {
      // if(selectedProblems.length===0){
      //   toast.error('Please add atleast one problem');
      //   return;
      // }
      if (contest) {
        // If editing an existing contest, send PUT request
        const { data } = await proxy.put(`/contests/${contest._id}`, contestData);
        toast.success(data.message);
      } else {
        // If creating a new contest, send POST request
        const { data } = await proxy.post('/contests', contestData);
        toast.success(data.message);
      }
      onClose(); // Close the form dialog
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: '20px',
        maxWidth: '800px', // Increased width
        margin: 'auto',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9'
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        {contest ? 'Edit Contest' : 'Create Contest'}
      </Typography>

      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        required
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />

      <TextField
        label="Start Time"
        type="datetime-local"
        value={startTime}
        onChange={(e) => setStartTime(e.target.value)}
        fullWidth
        required
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 2 }}
      />
      
      <TextField
        label="End Time"
        type="datetime-local"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        fullWidth
        required
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 2 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="duration-label">Duration</InputLabel>
        <Select
          labelId="duration-label"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          label="Duration"
        >
          <MenuItem value={30}>30 mins</MenuItem>
          <MenuItem value={60}>60 mins</MenuItem>
          <MenuItem value={90}>90 mins</MenuItem>
          <MenuItem value={120}>120 mins</MenuItem>
          <MenuItem value={180}>180 mins</MenuItem>
        </Select>
      </FormControl>

      <Autocomplete
        multiple
        options={availableProblems}
        getOptionLabel={(option) => option.title}
        onChange={handleProblemSelect}
        renderInput={(params) => (
          <TextField {...params} label="Select Problems" variant="outlined" fullWidth sx={{ mb: 2 }} />
        )}
        value={availableProblems.filter(problem => selectedProblems.includes(problem._id))} // Ensure selected problems are shown correctly
      />

      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        {selectedProblems.map((problemId) => {
          const problem = availableProblems.find(p => p._id === problemId);
          return (
            <Chip
              key={problemId}
              label={problem ? problem.title : 'Unknown Problem'}
              onDelete={() => handleProblemRemove(problemId)}
            />
          );
        })}
      </Stack>

      <Button type="submit" variant="contained" color="primary" fullWidth>
        {contest ? 'Update Contest' : 'Create Contest'}
      </Button>
    </Box>
  );
};

export default CreateContest;
