import React, { useState } from 'react';
import { Box, Button, TextField, Modal, Typography, Snackbar, Alert } from '@mui/material';
import proxy from '../api/axios';
import { toast } from 'react-toastify';

const FeedbackForm = ({ open, handleClose }) => {
  const [feedback, setFeedback] = useState('');
  const [name, setName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = async () => {
    if(!name){
      toast.error('Enter your name to share feedback');
      return;
    }
    if(!feedback){
      toast.error('Enter your feedback');
      return;
    }
    try {
      const url = window.location.href;
      
      await proxy.post('/feedback', { feedback, name, url });
      toast.success('Thanks for your feedback.')
      setSnackbarMessage('Feedback submitted successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Failed to submit feedback. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      handleClose();
      setFeedback('');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ 
        bgcolor: 'background.paper', 
        borderRadius: '8px', 
        boxShadow: 24, 
        p: 4, 
        width: '400px', 
        margin: 'auto', 
        mt: '5%'
      }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          We value your feedback!
        </Typography>
        <TextField
          multiline
          label="Your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <TextField
          multiline
          minRows={4}
          label="Share your experience"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
          Submit Feedback
        </Button>

        {/* Snackbar for feedback submission status */}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default FeedbackForm;
