import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, CircularProgress, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
import proxy from '../api/axios';
import { Helmet } from 'react-helmet';
const ProgramsPage = () => {
  const theme = useTheme();
  const [level, setLevel] = useState('1'); // Default to level 1
  const [problems, setProblems] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch problems from API on mount
  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await proxy.get('/problems/dsa-programs');
        const problemData = response.data;

        // Organize data by level and section
        const structuredProblems = {};
        problemData.forEach((problem) => {
          const { level, section } = problem;
          if (!structuredProblems[level]) {
            structuredProblems[level] = {};
          }
          if (!structuredProblems[level][section]) {
            structuredProblems[level][section] = [];
          }
          structuredProblems[level][section].push(problem);
        });

        // Sort problems by displayOrder within each section
        Object.keys(structuredProblems).forEach((lvl) => {
          Object.keys(structuredProblems[lvl]).forEach((sect) => {
            structuredProblems[lvl][sect].sort((a, b) => a.displayOrder - b.displayOrder);
          });
        });

        setProblems(structuredProblems);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching problems:", error);
        setLoading(false);
      }
    };

    fetchProblems();
  }, []);

  // Calculate the day number based on the global index
  const getDayNumber = (index) => {
    const problemsPerDay = 5;
    return Math.floor(index / problemsPerDay) + 1; // Each 5 problems form a new day
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <CircularProgress />
        <span style={{ marginLeft: 7, color: '#1497c1' }}>Loading Programs</span>
      </Box>
    );
  }

  let problemCounter = 0; // Track the index across sections
  const totalProblems = Object.values(problems[level] || {}).flat().length;
  const totalDays = Math.ceil(totalProblems / 5); // Calculate total days based on 5 problems per day

  return (
    <Box sx={{ padding: '20px', width: '100%', maxWidth: '1200px', margin: '0 auto', mt:5, backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff' }}>
      <Helmet>
        <title>DSA Programs | CrafterHack</title>
        <meta name="description" content={`This is the page for structured coding questions on level 1 and level 2`} />
      </Helmet>
      {/* Level Selector */}
      <Select
        value={level}
        onChange={(e) => setLevel(e.target.value)}
        sx={{ mb: 2, width: '70%', maxWidth: '120px' }}
        size='small'
      >
        {Object.keys(problems).map((lvl) => (
          <MenuItem key={lvl} value={lvl}>
            {'Level ' + lvl.charAt(0).toUpperCase() + lvl.slice(1)}
          </MenuItem>
        ))}
      </Select>

      {/* Problems List */}
      {problems[level] ? (
        Object.keys(problems[level]).map((section) => {
          const sectionProblems = problems[level][section];

          return (
            <Accordion key={section} sx={{ mb: 2, bgcolor: 'background.main' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  color: 'text.primary',
                }}
              >
                <Typography sx={{ display: 'flex', alignItems: 'center', fontSize:'14px' }}>
                  <FormatListBulletedIcon sx={{ mr: 1 }} />
                  {section}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ flexDirection: 'column' }}>
                {sectionProblems.map((problem) => {
                  const dayNumber = getDayNumber(problemCounter); // Calculate the day number for each problem
                  problemCounter++; // Increment counter for each problem

                  const displayDay = dayNumber === totalDays ? 'Today' : `Day ${dayNumber}`;

                  return (
                    <Paper key={problem._id} sx={{ mb: 1, width: '100%' }}>
                      <Button
                        component={Link}
                        to={`/problems/${problem.slug}`}
                        variant="outlined"
                        sx={{
                          width: '100%',
                          justifyContent: 'space-between',
                          color: 'text.primary',
                          bgcolor: 'background.paper',
                          '&:hover': { bgcolor: 'primary.light' }
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <CodeIcon sx={{ mr: 1 }} />
                          <Typography sx={{ flexGrow: 1, fontSize: '12px' }}>{problem.title}</Typography>
                          {problem.isSolved && <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />}
                          <Typography sx={{ mr: 2 }}>{displayDay}</Typography> {/* Display Today or Day number */}
                        </Box>
                      </Button>
                    </Paper>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Typography>No problems available for this level.</Typography>
      )}
    </Box>
  );
};

export default ProgramsPage;
