import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory
import { Box, TextField, Button, Alert, Typography } from '@mui/material';
import proxy from '../../api/axios';

const resetPasswordStyle = {
  maxWidth: '500px',
  margin: '0 auto',
  padding: '30px',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

const inputStyle = {
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: '8px',
  color: 'white',
  height: 48,
  padding: '0 30px',
  marginTop: '16px',
  width: '100%',
};

const ResetPassword = () => {
  const { token } = useParams(); // Retrieve reset token from URL
  const navigate = useNavigate(); // useNavigate replaces useHistory
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await proxy.post(`/auth/reset-password/`, {token, password });
      
      if (response.data.success) {
        setSuccess('Password reset successful! You can login now');
        setTimeout(() => {
          navigate('/'); // Redirect to login page after success
        }, 3000);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('Error resetting password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={resetPasswordStyle}>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 3 }}>
        Reset Your Password
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <TextField
        label="New Password"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={inputStyle}
      />
      <TextField
        label="Confirm Password"
        type="password"
        fullWidth
        margin="normal"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        sx={inputStyle}
      />
      <Button
        fullWidth
        variant="contained"
        sx={buttonStyle}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Resetting Password...' : 'Reset Password'}
      </Button>
    </Box>
  );
};

export default ResetPassword;
